<template>
  <v-app>
    <div class="fill-height grey darken-4">
      <router-view />
    </div>
  </v-app>
</template>

<script>
export default {
  name: 'LoginLayout',
}
</script>

<style scoped>

</style>
